import { Link } from "gatsby"
import React, { useRef, useState } from "react"

import styled from "styled-components"
import { useLogo } from "../hooks/useLogo"
import { GatsbyImage } from "gatsby-plugin-image"
import { useLogoYellow } from "../hooks/useLogoYellow"
import { spinn } from "../utils/Animations"

const Menu: React.FC = () => {
  const logo = useLogoYellow()
  const { description, gatsbyImageData } = logo
  const logoRef = useRef()
  const handleClick = () => {
    logoRef.current.classList.add("spinn")

    setTimeout(() => {
      console.log("hej")
      logoRef.current.classList.remove("spinn")
    }, 500)
  }
  return (
    <StyledMenu>
      <StyledWrapper>
        <Grouper>
          <StyledScrollLink activeStyle={{ color: "red" }} to="#about">
            About
          </StyledScrollLink>
          <StyledScrollLink to="#news">News</StyledScrollLink>
          <StyledScrollLink to="#gigs">Gigs</StyledScrollLink>
        </Grouper>
        <StyledLogoWrapper
          onClick={() => handleClick()}
          ref={logoRef}
          to="#top"
        >
          <GatsbyImage
            style={{ height: "100%" }}
            imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
            alt={description}
            image={gatsbyImageData}
          />
        </StyledLogoWrapper>
        <Grouper>
          {/* <StyledScrollLink to="/#videos">Videos</StyledScrollLink> */}
          <StyledScrollLink to="/#contact">Bookings & Contact</StyledScrollLink>
        </Grouper>
      </StyledWrapper>
    </StyledMenu>
  )
}
const Grouper = styled.div`
  display: flex;
  display: none;
  @media (min-width: 600px) {
    display: unset;
  }
`
const StyledMenu = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100px;
  background: white;
  z-index: 20;
  padding: 0px 90px;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  border-top: 5px solid var(--highlight);
`
const StyledWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinn {
    /* transform: scale(1.05);
    margin-bottom: -10px; */
    animation: ${spinn} 0.5s;
  }
  @media (min-width: 800px) {
    justify-content: space-between;
  }
`

const StyledLogoWrapper = styled(Link)`
  height: 95px;
  width: 95px;
  padding: 5px;

  cursor: pointer;

  transition: all 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  @media (min-width: 800px) {
    height: 75px;
    width: 75px;
    margin: 0px 100px;
    padding: unset;
  }
`
const StyledScrollLink = styled(Link)`
  font-family: "Arbotek";
  letter-spacing: 1.5px;
  cursor: pointer;
  margin: 0px 40px;
  font-size: 22px;
  position: relative;
  text-decoration: none;
  color: black;
  /* &:active {
    &:after {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 100%;
      height: 3px;
      border-radius: 100px;
      background-color: var(--highlight);
    }
  } */
`

export default Menu
