import React from "react"
import styled from "styled-components"
import { GlobalStyle } from "../shared/Styles"
import Footer from "../sections/Footer"
import Menu from "./Menu"
import Seo from "./Seo"

type SEO = {
  seoTitle: string
  seoDescription: string
  seoImage?: string
  slug: string
}
type PageWrapperProps = {
  children: any
  seo: SEO
}
const PageWrapper: React.FC<PageWrapperProps> = ({ children, seo }) => {
  return (
    <StyledPageWrapper>
      <PageWidth>
        <Seo
          title={seo.seoTitle}
          description={seo.seoDescription}
          slug={seo.slug ? seo.slug : "homepage"}
        />
        <GlobalStyle />

        <Menu />
        {children}
        <Footer />
      </PageWidth>
    </StyledPageWrapper>
  )
}
const StyledPageWrapper = styled.div`
  max-width: 100vw;
  position: relative;
  overflow: hidden;
`
const PageWidth = styled.div`
  max-width: 1450px;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 0 25px;
  }
`
export default PageWrapper
