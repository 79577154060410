import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import styled from "styled-components"

const Contact = () => {
  const [fieldValues, setFieldValues] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [formSent, setFormSent] = useState(false)
  const formRef = useRef()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  useEffect(() => {
    console.log(fieldValues)
  }, [fieldValues])
  const handleSubmit = () => {
    if (fieldValues.name && fieldValues.email && fieldValues.message) {
      const formData = new FormData()
      Object.entries(fieldValues).forEach(([key, value]) => {
        if (value === "") {
          return
        }
        formData.append(key, value)
      })
      fetch("https://getform.io/f/f6ae4bbf-25fb-41f3-a470-a9f95cfbb0f3 ", {
        method: "POST",
        body: formData,
      })
        .then((res) => {
          if (res.status === 200) {
            setFormSent(true)
            setFieldValues({ name: "", email: "", message: "" })
          }
        })
        .catch((error) => console.log(error))
    }
  }

  return (
    <StyledContact id="contact">
      <StyledHeader>
        <h2>Book us!</h2>
        <p>(you know you want to)</p>
      </StyledHeader>
      {formSent && <ThankYou>Thank you!</ThankYou>}

      <StyledForm ref={formRef} onSubmit={(e) => handleSubmit(e)}>
        <InputWrapper>
          <label>Name</label>
          <StyledInput
            onChange={(e) => handleChange(e)}
            type="text"
            name="name"
            value={fieldValues.name}
            required
          ></StyledInput>
        </InputWrapper>
        <InputWrapper>
          <label>Email</label>
          <StyledInput
            onChange={(e) => handleChange(e)}
            type="email"
            name="email"
            value={fieldValues.email}
            required
          />
        </InputWrapper>
        <InputWrapper>
          <label>Message</label>
          <StyledMessageInput
            onChange={(e) => handleChange(e)}
            type="text"
            rows="10"
            name="message"
            value={fieldValues.message}
            required
          />
        </InputWrapper>
        <StyledButton
          onClick={() => handleSubmit()}
          name="submit"
          type="submit"
        >
          Submit
        </StyledButton>
      </StyledForm>
    </StyledContact>
  )
}

const ThankYou = styled.h4`
  color: var(--button);
  margin-bottom: -5rem;

  margin-top: 2rem;
  font-family: "Rozha One", serif;
`
const StyledContact = styled.div`
  padding: 100px 0px;
  text-align: center;
`
const StyledHeader = styled.div`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    max-width: 700px;
    width: 100%;
    border-radius: 30px;
    height: 2px;
    background-color: white;
    background: rgb(219, 72, 39);
    background: radial-gradient(
      circle,
      rgba(219, 72, 39, 0.9387955865940126) 10%,
      rgba(177, 68, 47, 0) 97%
    );
    left: 50%;
    transform: translateX(-50%);
  }
  h2 {
    margin: 0;
  }
  p {
    margin-top: -20px;
    font-size: 15px;
    letter-spacing: 1px;
  }
  padding-bottom: 10px;
`
const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

  input,
  input:focus {
    outline: none;
    border: specify yours;
  }
`

const StyledButton = styled.button`
  margin: 20px 0px;
  padding: 10px 40px;
  background-color: var(--button);
  color: var(--secondary);
  border: none;
  cursor: pointer;
  border-radius: 4px;
  color: white;
  font-weight: bolder;
  letter-spacing: 2px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  &:hover {
    background-color: #feb03b;
    color: black;
  }
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 300px;

  label {
    margin-bottom: -8px;
    font-size: 14px;
  }
`

const StyledInput = styled.input`
  width: 100%;
  border-radius: 5px;
  margin: 10px 0px;
  height: 30px;
  border: 1.5px solid #db3f277b;

  padding-left: 10px;
  letter-spacing: 1px;
`
const StyledMessageInput = styled.textarea`
  margin-top: 10px;
  width: 100%;
  outline: none;
  border: 1.5px solid #db3f2760;
  border-radius: 5px;
  padding: 10px;
  line-height: 20px;
  letter-spacing: 1px;
`
export default Contact
