import React from "react"
import styled from "styled-components"
import Article from "../components/Article"
import Carousel from "../components/Carousel"
import { ContentfulSectionNews } from "../shared/contentful.types"
import Slider from "react-slick"

type NewsProps = {
  data: ContentfulSectionNews
}
const NewsNew: React.FC<NewsProps> = ({ data }) => {
  const settings = {
    className: "slider variable-width",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  }

  const moreArticles = [...data.articles, ...data.articles]
  return (
    // <StyledSection id="news">
    //   <h2>{data.heading}</h2>
    //   <div style={{ padding: "2rem 0" }}>
    //     <Slider {...settings}>
    //       {moreArticles.map((article) => (
    //         <Article article={article} />
    //       ))}
    //     </Slider>
    //   </div>
    // </StyledSection>
    <></>
  )
}
const Slide = styled.div``
const StyledSection = styled.section`
  position: relative;

  /* the slides */
  h2 {
    color: var(--white);
  }
  padding: 20rem 0;

  &:after {
    content: "";
    position: absolute;
    width: 100vw;
    top: 0;

    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary);
    z-index: -1;
  }
  .slick-slide {
    /* margin: 0 20px; */
  }
`
export default NewsNew
