import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import React, { useEffect } from "react"
import styled from "styled-components"
import BandMember from "../components/BandMember"
import MemberInfo from "../components/MemberInfo"
import { useRevolution } from "../hooks/useAlbumCover"
import { SectionAbout } from "../shared/contentful.types"

type AboutProps = {
  data: SectionAbout
}
const About: React.FC<AboutProps> = ({ data }) => {
  const { text, heading, image, bandMembers, anchorLink, layout } = data
  const { contentfulAsset } = useRevolution()

  return (
    <StyledSection darkMode={data?.darkMode} id={anchorLink} layout={layout}>
      {/* <BGWrapper>
        <StaticImage
          style={{ width: "100%", height: "100%" }}
          imgStyle={{ objectFit: "contain" }}
          alt="background"
          src="../images/car.png"
        />
      </BGWrapper> */}
      <StyledTextContent>
        <StyledHeading>
          <h2>{heading}</h2>
        </StyledHeading>
        {renderRichText(text)}
      </StyledTextContent>
      <ImageWrapper layout={layout}>
        <GatsbyImage
          style={{
            height: "100%",
            width: "100%",
          }}
          imgStyle={{
            objectFit: "contain",
          }}
          alt={image.description}
          image={image.gatsbyImageData}
        />
      </ImageWrapper>

      {/* <StyledMembers>
        {bandMembers.map((member, i) => {
          return <BandMember key={i} position={i} member={member} />
        })}
      </StyledMembers> */}
    </StyledSection>
  )
}

const StyledSection = styled.section`
  position: relative;
  padding: 5rem 0 10rem;

  @media (min-width: 1440px) {
    display: grid;
    grid-template-columns: ${(props) => {
      return props.layout === "1 Column" ? "1fr" : "1fr 1fr"
    }};
  }
  * {
    color: ${(props) => (props.darkMode ? "var(--white)" : "var(--black)")};
  }
  h2 {
    margin: 50px 0px;
  }
  width: 100%;
  height: 100%;
  position: relative;
  &:after {
    z-index: -2;
    content: "";
    background-color: ${(props) =>
      props.darkMode ? "var(--black)" : "var(--white)"};
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`
const StyledHeading = styled.div`
  text-align: center;
  margin-top: 120px;
  max-width: 1200px;
  margin: 0 auto;
`

const StyledTextContent = styled.div`
  width: 100%;
  margin: -20px auto 90px;

  p {
    margin: 0 auto;
    margin-bottom: 10px;
    max-width: 600px;

    text-align: center;
  }
  a {
    color: var(--highlight);
  }
`

const ImageWrapper = styled.div`
  align-self: center;
  @media (min-width: 870px) {
    height: ${(props) => (props.layout === "1 Column" ? "800px" : "100%")};

    /* width: ${(props) => (props.layout === "1 Column" ? "100%" : "50%")}; */
  }

  /* margin-bottom: 400px; */
`

export default About
