import React from "react"
import { Helmet } from "react-helmet"

export type SeoProps = {
  description?: string
  title?: string
  image?: string
  slug?: string
}
const Seo: React.FC<SeoProps> = ({ title, description, image, slug }) => {
  const metaTitle = title
  const metaDescription = `${description || "hej"}`
  const metaUrl = `https://amonglynx.gatsbyjs.io/${
    slug === "homepage" || !slug ? "" : slug
  }`

  return (
    <Helmet
      title={metaTitle}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: metaTitle,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:image",
          content: "",
        },
        {
          property: "og:url",
          content: metaUrl,
        },
        {
          name: "twitter:card",
          content: "",
        },
        {
          name: "twitter:title",
          content: metaTitle,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
        {
          name: "twitter:image",
          content: "",
        },
        {},
      ]}
    >
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Press+Start+2P&family=Supermercado+One&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  )
}

export default Seo
