import { keyframes } from "styled-components"

export const spinn = keyframes`
    0% {
     transform: translateY(0)  rotate(0);
    }
    35% {
        transform: translateY(1rem)  rotate(0);   
    }
     36% {
        transform: translateY(1rem)  rotate(.1turn);   
    }
    50% {
        transform: translateY(1rem);
    }
    100% {
      transform: translateY(0) rotate(1turn);
    }
  `
