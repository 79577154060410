import { INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React from "react"
import styled from "styled-components"
import { ContentfulGig } from "../shared/contentful.types"

type GigProps = {
  data: ContentfulGig
}
const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a target="_blank" href={node.data.uri}>
          {node.content[0].value}
        </a>
      )
    },
  },
}
const Gig: React.FC<GigProps> = ({ data }) => {
  const { date, where, text, day, month, year } = data

  const todayDate = new Date()

  const gigDate = new Date(date)

  return (
    <StyledGig old={todayDate < gigDate}>
      <StyledDate>{`${year}-${month}-${day}`}</StyledDate>
      <StyledCity>{data.where}</StyledCity>
      <StyledDescription>{renderRichText(text, options)}</StyledDescription>
    </StyledGig>
  )
}

const StyledGig = styled.li`
  position: relative;
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 4px;
  padding: 20px 0px;

  * {
    /* color: ${(props) => !props.old && "grey"}; */
    color: #ececec;
  }
  a {
    color: var(--highlight);
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--highlight);
    background-color: var(--primary);

    background: linear-gradient(90deg, var(--primary) 25%, #06010e 90%);
    left: 0;
    /* transform: translateX(-50%); */
  }

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const StyledEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`

const StyledCity = styled(StyledEntry)`
  p {
    font-weight: bold;
  }
`
const StyledDate = styled(StyledEntry)``
const StyledDescription = styled(StyledEntry)``
export default Gig
