import { useStaticQuery, graphql } from "gatsby"

// yellow, black Purple
export const useLogoYellow = () => {
  const asset = useStaticQuery(graphql`
    {
      contentfulAsset(title: { eq: "among lynx logo yellow" }) {
        description
        gatsbyImageData
      }
    }
  `)
  const { contentfulAsset } = asset

  return contentfulAsset
}
