import React, { useEffect, useRef } from "react"
import styled from "styled-components"

import { useLogoYellow } from "../hooks/useLogoYellow"
import { ContentfulHero, LongText } from "../shared/contentful.types"
import ReactPlayer from "react-player/lazy"

type HeroProps = {
  data: ContentfulHero
}
const Hero: React.FC<HeroProps> = ({ data }) => {
  const logo = useLogoYellow()

  const { description, gatsbyImageData } = logo

  const vidRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    if (vidRef.current) {
      vidRef.current.volume = 0
    }
  }, [])

  console.log(data.media.file.url)

  return (
    <StyledSection id="top">
      <VideoWrapper>
        <Video
          className="react-player"
          url={`${data?.media?.file?.url}`}
          loop={true}
          playsinline={true}
          width="100%"
          height="100%"
          volume={0}
          muted={true}
          ref={vidRef}
          playing
        />
      </VideoWrapper>

      <StyledHeading>
        <h1>{data.heading}</h1>
        <h2>{data.heading2}</h2>
      </StyledHeading>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  height: 90vh;
  max-height: 1100px;
  min-height: 800px;
  position: relative;
  @font-face {
    font-family: "Arbotek";
    src: url("/fonts/Arbotek-Ultra.otf") format("openType");
  }
`

const VideoWrapper = styled.div`
  position: absolute;

  width: 100vw;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;

  filter: grayscale(40%);
`
const Video = styled(ReactPlayer)`
  video {
    /* background: url("https://images.ctfassets.net/bburs0da8ykh/6LNcCeAVhl7MyMCI34NQnf/009896a5968f4bf8aee769f2de120476/Screenshot_2023-02-17_at_10.26.11.webp"); */
    object-fit: cover;
  }
`

const StyledHeading = styled.div`
  position: absolute;
  color: white;
  top: 40px;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    font-family: "Arbotek";
    font-weight: bolder;
    letter-spacing: 20px;
    background: var(--highlight);
    background: linear-gradient(
      180deg,
      var(--primary) 50%,
      var(--highlight) 80%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (min-width: 1440px) {
      font-size: 150px;
    }
  }

  margin-top: -100px;
`

export default Hero
