import React from "react"
import styled from "styled-components"

const Footer: React.FC = () => {
  return <StyledFooter>Footer</StyledFooter>
}

const StyledFooter = styled.section`
  height: 100px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;

    height: 100%;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);

    background-color: var(--primary);
  }
`

export default Footer
