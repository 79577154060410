import { graphql } from "gatsby"
import React from "react"

import Menu from "../components/Menu"
import PageWrapper from "../components/PageWrapper"
import { SectionComponents } from "../utils/SectionSelector"

type Section = {
  __typename: string
}
type Node = {
  slug: string
  seoTitle: string
  seoDescription: {
    seoDescription: string
  }
  sections: Section[]
}
type AllContentfulPage = {
  nodes: Node[]
}
type Data = {
  allContentfulPage: AllContentfulPage
}
type PageProps = {
  data: Data
}
const Page: React.FC<PageProps> = ({ data }) => {
  const sections = data.allContentfulPage.nodes[0].sections
  const {
    seoTitle,
    seoDescription: { seoDescription },
    slug,
  } = data.allContentfulPage.nodes[0]

  return (
    <PageWrapper seo={{ seoTitle, seoDescription: seoDescription, slug }}>
      {sections.map((section, i) => {
        const Component: React.ElementType =
          SectionComponents[section.__typename]

        if (!SectionComponents[section.__typename]) {
          return <div>`${section.__typename}`</div>
        } else {
          return <Component index={i} data={section} />
        }
      })}
    </PageWrapper>
  )
}
export const query = graphql`
  query MyPageQuery($id: String) {
    allContentfulPage(filter: { id: { eq: $id } }) {
      nodes {
        seoTitle
        seoDescription {
          seoDescription
        }
        sections {
          ... on ContentfulSectionNews {
            __typename
            heading

            articles {
              heading
              image {
                gatsbyImageData
              }
              imageCrop
              text {
                raw
              }
            }
          }
          ... on ContentfulSectionVideos {
            __typename
            videos {
              for
              url
            }
          }

          ... on ContentfulSectionContact {
            __typename
          }
          ... on ContentfulSectionAbout {
            __typename
            heading
            darkMode
            anchorLink
            layout
            text {
              raw
            }
            bandMembers {
              firstName
              lastName
              info
              image {
                gatsbyImageData
              }
            }
            image {
              gatsbyImageData(
                width: 1000
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
              )
            }
          }
          ... on ContentfulSectionGigs {
            __typename
            heading
            gigs {
              __typename
              for
              date
              month: date(formatString: "MM", locale: "sv")
              day: date(formatString: "DD")
              year: date(formatString: "YY")
              where
              text {
                raw
              }
            }
          }

          ... on ContentfulSectionHero {
            __typename
            heading
            heading2
            media {
              description
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
export default Page
