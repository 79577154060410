import Footer from "../sections/Footer"
import Hero from "../sections/Hero"
import About from "../sections/About"
import VideoSlider from "../sections/VideoSlider"
import Gigs from "../sections/Gigs"
import News from "../sections/News"
import Contact from "../sections/Contact"
import NewsNew from "../sections/NewsNews"

export type Components = {
  [sectionName: string]: React.ElementType
}

export const SectionComponents: Components = {
  ContentfulSectionHero: Hero,
  ContentfulFooter: Footer,
  ContentfulSectionAbout: About,
  ContentfulSectionVideos: VideoSlider,
  ContentfulSectionGigs: Gigs,
  ContentfulSectionNews: NewsNew,
  ContentfulSectionContact: Contact,
}
