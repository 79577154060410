import React from "react"
import styled from "styled-components"
import Gig from "../components/Gig"
import SectionHeading from "../components/SectionHeading"
import { SectionGigs } from "../shared/contentful.types"

type SectionGigsProps = {
  data: SectionGigs
}
const Gigs: React.FC<SectionGigsProps> = ({ data }) => {
  return (
    <StyledSection id="gigs">
      <h2>{data.heading}</h2>
      <h3>2023</h3>
      <StyledGigList>
        <ul>
          {data.gigs.map((gig) => {
            return <Gig data={gig}></Gig>
          })}
        </ul>
      </StyledGigList>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  padding: 120px 0px;
  position: relative;

  * {
    color: var(--white);
    text-align: center;
  }
  h2 {
    margin-bottom: 60px;
  }
  h3 {
    margin-bottom: 30px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 80px;
      border-radius: 30px;
      height: 1px;
      background-color: white;
      background-color: var(--highlight);
      background-color: var(--primary);

      background: linear-gradient(90deg, var(--primary) 23%, #150039 100%);
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &:after {
    z-index: -1;
    content: "";
    background-color: var(--black);
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`

const StyledGigList = styled.div`
  text-align: center;
  /* display: inline-block; */
  display: flex;
  justify-content: center;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`
export default Gigs
