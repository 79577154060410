import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: 'Arbotek';
    src: url( '/fonts/Arbotek-Ultra.otf') format('openType');
}
@font-face {
    font-family: 'KiloGram';
    src:url('/fonts/webfonts_KiloGram/KiloGram.ttf.woff') format('woff'),
        url('/fonts/webfonts_KiloGram/KiloGram.ttf.svg#KiloGram') format('svg'),
        url('/fonts/webfonts_KiloGram/KiloGram.ttf.eot'),
        url('/fonts/webfonts_KiloGram/KiloGram.ttf.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Supermercado+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;700&family=Cinzel:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
  --orange: #df5f29a8;
  --red: #DC181C;
  --pink: #F80088;
  --purple: #6B097B;
  --black: #030004;
  --white: #fefbfd;
  --primary: #6B097B;
  --secondary: #350c7a;
  --highlight: #f800aa;

  --primary: #DB4027;
  --secondary: #290621;
  --highlight: #ff0004;
  --white: #fcfcfc;
  --button: #ff890a;

}
html {
  font-size: 10px;
}

body {
    /* font-family: 'Cabin', sans-serif; */
    /* font-family: 'Cinzel', serif; */
    /* font-family: 'Playfair Display SC', serif; */

}


*,
*::before,
*::after {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Arbotek';
  margin: 0;
  font-family: Arbotek;


}
h1 {
  font-size: 6rem;

  @media(min-width: 400px){
    font-size: 7rem;

  }
  @media (min-width: 1200px) {
  font-size: 8rem;
    
  }
  @media (min-width: 1500px) {
  font-size: 10rem;

  }
}
h2 {
  font-size: 3.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  @media (min-width: 1200px) {
     font-size: 7rem;
  }
  @media (min-width: 1400px) {
     font-size: 7rem;
  }
}
h3 {
  font-size: 4rem;
  @media (min-width: 1200px) {
  }
}
h4 {
  font-size: 2rem;
  font-weight: 900;
  @media (min-width: 900px) {
  }
}


body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}
ul[role="list"],
ol[role="list"] {
  list-style: none;
}
html:focus-within {
  scroll-behavior: smooth;
}
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}
img,
picture {
  max-width: 100%;
  display: block;
}
input,
button,
textarea,
select {
  font: inherit;
}
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
a {
  text-decoration: none;
}
p {
font-family: 'Rozha One', serif;
font-size: 18px;
&:empty {
  display: none;
}

@media(min-width: 1441px ){
  font-size: 18px
}

}
b {
  font-weight: bold;
}



`
