import { useStaticQuery, graphql } from "gatsby"

export const useRevolution = () => {
  const revolutionCover = useStaticQuery(graphql`
    {
      contentfulAsset(title: { eq: "Album Cover Revolution" }) {
        gatsbyImageData
      }
    }
  `)
  return revolutionCover
}
