import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Pagination from "../components/Pagination"
import SectionHeading from "../components/SectionHeading"
type Video = {
  type: string
  url: string
}
type Data = {
  videos: Video[]
}
type VideoSliderProps = {
  data: Data
}
const VideoSlider: React.FC<VideoSliderProps> = ({ data }) => {
  const [sliderPosition, setSliderPosition] = useState<number>(0)
  const [slideWidth, setSlideWidth] = useState<number>(0)
  const videoSlideRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setSlideWidth(entries[0].contentRect.width + 150)
    })
    videoSlideRef.current && observer.observe(videoSlideRef.current)
  }, [videoSlideRef])

  useEffect(() => {
    console.log(slideWidth)
  }, [slideWidth])

  // return (
  //   <StyledSection id="videos">
  //     <SectionHeading heading="Videos"></SectionHeading>
  //     <StyledSlider sliderPosition={sliderPosition} slideWidth={slideWidth}>
  //       {data.videos.map((video) => {
  //         const embedUrl = video.url.replace("watch?v=", "embed/")

  //         return (
  //           <StyledSlideItem ref={videoSlideRef}>
  //             <VideoWrapper>
  //               <StyledYoutubeIframe
  //                 width="100%"
  //                 height="100%"
  //                 src={embedUrl}
  //                 title="YouTube video player"
  //                 allowFullScreen={true}
  //               ></StyledYoutubeIframe>
  //             </VideoWrapper>
  //           </StyledSlideItem>
  //         )
  //       })}
  //     </StyledSlider>
  //     <Pagination
  //       setSliderPosition={setSliderPosition}
  //       sliderPosition={sliderPosition}
  //       slides={data.videos.length}
  //     />
  //   </StyledSection>
  // )
  return <></>
}

const StyledSection = styled.section`
  padding: 120px 0px;
  position: relative;
  &:after {
    z-index: -1;
    content: "";
    background-color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`

type StyledSliderProps = {
  sliderPosition: number
  slideWidth: number
}
const StyledSlider = styled.div<StyledSliderProps>`
  display: flex;
  width: 100%;
  gap: 150px;

  transform: ${(props) => {
    return `translateX(${props.sliderPosition * props.slideWidth * -1}px)`
  }};
  transition: 0.6s ease;
`

const StyledSlideItem = styled.div`
  min-width: 100%;

  display: flex;
  justify-content: center;
  max-height: 1000px;
  min-height: 60vh;
`
const VideoWrapper = styled.div`
  max-height: 1000px;
  max-width: 100%;
  aspect-ratio: 16/9;
`
const StyledYoutubeIframe = styled.iframe``

export default VideoSlider
